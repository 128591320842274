<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <b-col class="col-6">
                    <ValidationProvider
                        name="campus_id"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox
                                v-model="form.campus_id"
                            ></campus-selectbox>
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>

                </b-col>
                <b-col class="col-6">
                    <ValidationProvider
                        name="building_id"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('building')">
                            <building-selectbox
                                :campus_id="form.campus_id"
                                v-model="form.building_id"
                            ></building-selectbox>
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </b-col>
                <b-form-group class="col-6" :label="$t('name')">
                    <ValidationProvider
                        name="name"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group class="mb-3" prepend="TR">
                            <b-form-input
                                v-model="form.name"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                    <ValidationProvider
                        name="name_en"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group prepend="EN">
                            <b-form-input
                                v-model="form.name_en"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                        />
                    </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-6" :label="$t('capacity')">
                    <ValidationProvider
                        name="capacity"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group class="mb-3" prepend="Sınıf">
                            <b-form-input
                                type="number"
                                v-model="form.capacity"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                    <ValidationProvider
                        name="exam_capacity"
                        v-slot="{ valid, errors }"
                    >
                        <b-input-group prepend="Sınav">
                            <b-form-input
                                type="number"
                                v-model="form.exam_capacity"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                        />
                    </ValidationProvider>
                </b-form-group>
                <div class="col-4">
                    <ValidationProvider
                        name="type"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('classroom_types')">
                            <parameter-selectbox
                                code="classroom_types"
                                v-model="form.type"
                            />
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider
                        name="seating_type"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('seating_types')">
                            <parameter-selectbox
                                code="seating_types"
                                v-model="form.seating_type"
                            />
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider
                        name="board_type"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('board_types')">
                            <parameter-selectbox
                                code="board_types"
                                v-model="form.board_type"
                            />
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider
                        name="display_system"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('display_systems')">
                            <parameter-selectbox
                                code="display_systems"
                                v-model="form.display_system"
                            />
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider
                        name="sound_system"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('sound_systems')">
                            <parameter-selectbox
                                code="sound_systems"
                                v-model="form.sound_system"
                            />
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </div>

                <div class="col-4">
                    <ValidationProvider
                        name="status"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                code="status"
                                v-model="form.status"
                            ></status-selectbox>
                        </b-form-group>
                        <b-form-invalid-feedback
                            v-if="errors[0]"
                            v-html="errors[0]"
                            class="mb-2"
                        />
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider
                        name="explanation"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('explanation')">
                            <b-form-input
                                v-model="form.explanation"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="createForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
    import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import ClassroomService from "@/services/ClassroomService";

    export default {
        components: {
            ValidationProvider, ValidationObserver,

            CampusSelectbox,
            BuildingSelectbox,
            ParameterSelectbox,
            StatusSelectbox
        },
        data() {
            return {
                formLoading: false,
                form: {
                    campus_id: null,
                    building_id: null,
                    capacity: null,
                    exam_capacity: null,
                    type: null,
                    seating_type: null,
                    board_type: null,
                    display_system: null,
                    sound_system: null,
                    status: null,
                    explanation: null,
                    name: null,
                    name_en: null,
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    this.formLoading = true;
                    ClassroomService.store(this.form)
                                    .then((response) => {
                                        this.$toast.success(this.$t("api." + response.data.message));
                                        this.$emit("createFormSuccess")
                                    })
                                    .catch((error) => {
                                        this.showErrors(error, this.$refs.formModalValidate)
                                    }).finally(() => {
                        this.formLoading = false;
                    });
                }
            }
        }
    }
</script>
